// extracted by mini-css-extract-plugin
export var MobileButton = "ServiceTemplate-module--MobileButton--uG7ad";
export var MobileLink = "ServiceTemplate-module--MobileLink--evhXR";
export var animationContainer = "ServiceTemplate-module--animationContainer--bmffy";
export var cardContainer = "ServiceTemplate-module--cardContainer--SCCGq";
export var cardContainerWrapper = "ServiceTemplate-module--cardContainerWrapper--CTrSY";
export var cardContainerwrapper = "ServiceTemplate-module--cardContainerwrapper--rpq7A";
export var content = "ServiceTemplate-module--content--OI9Bi";
export var page = "ServiceTemplate-module--page--Vsuuq";
export var pinnedDiv = "ServiceTemplate-module--pinnedDiv--+Tytw";
export var title = "ServiceTemplate-module--title--3bVWl";
export var topPadding = "ServiceTemplate-module--topPadding--6jbX9";