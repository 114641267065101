import * as styles from '@/styles/ServiceTemplate.module.css'

import { Link, graphql } from "gatsby"
import { useLayoutEffect, useRef, useState } from 'react';

import ASScroll from "@ashthornton/asscroll";
import Card from "../components/Card"
import Flexbox from '@/components/Flexbox'
import Form from '../components/Form';
import Layout from "../containers/layout"
import Page from "../components/Page"
import SEO from '@/components/SEO'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
import { useLocation } from '@reach/router'
import useWindowDimensions from '@/lib/getScreenSize'

const serviceTemplate = ({ data }) => {
  const content = data.markdownRemark.frontmatter.content;
  const examples = data.markdownRemark.frontmatter.examples;
  const exampleBullets = examples.map(el => el.example)
  const contact = data.markdownRemark.frontmatter.contact;

  let seoData = data.markdownRemark.frontmatter.seo;
  seoData.canonicalURL = useLocation();

  const cardRefs = useRef();
  const firstPageRef = useRef();
  const videoRef = useRef();

  gsap.registerPlugin(ScrollTrigger);

  /* Change playing state onmetadataload to ake sure the video is 'activated' on iOS */
  const [iOSPlaying, setiOSPlaying] = useState(true)

  const [isMobile, setIsMobile] = useState(false);

  const { width, height } = useWindowDimensions();


  useLayoutEffect(() => {

    ScrollTrigger.normalizeScroll(true)

    ScrollTrigger.config({
      limitCallbacks: true,
      ignoreMobileResize: true
    })

    const isTouch = 'ontouchstart' in document.documentElement

    const asscroll = new ASScroll({
      disableRaf: true,
      ease: 0.03,
      touchEase: 0.6,
    });

    asscroll.enable({
      restore: false
    });
    gsap.ticker.add(asscroll.update)

    ScrollTrigger.scrollerProxy(asscroll.containerElement, {
      scrollTop(value) {
        return arguments.length ? asscroll.currentPos = value : asscroll.currentPos;
      },
      getBoundingClientRect() {
        return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight }
      }
    });

    asscroll.on("update", ScrollTrigger.update);

    function resizeHandler() {
      return asscroll.resize;
    }
    ScrollTrigger.addEventListener("refresh",
      resizeHandler()
    );

    const scrollLength = 8000;
    const foo = gsap.utils.selector(cardRefs);
    const cards = foo('div');

    cards.forEach((el, i) => {
      const startPos = scrollLength / cards.length * i;
      const endPos = i !== cards.length ? (scrollLength / cards.length) * (i + 1) : scrollLength + 4000
      ScrollTrigger.create({
        trigger: firstPageRef.current,
        scroller: asscroll.containerElement,
        start: i === 0 ? 'top top' : `${startPos}px`,
        end: `${endPos}px`,
        scrub: true,
        onEnter: () => {
          gsap.to(el, { autoAlpha: 1, duration: 0.3 })

        },
        onEnterBack: () => {
          gsap.to(el, { autoAlpha: 1, duration: 0.3 })
        },
        onLeave: () => {
          gsap.to(el, { autoAlpha: 0, duration: 0.3 })

        },
        onLeaveBack: () => {
          if (i !== 0) {
            gsap.to(el, { autoAlpha: 0, duration: 0.3 })
          }
        },

      })
    })

    let videoTimeline = gsap.timeline({
      defaults: { duration: 1 },
      scrollTrigger: {
        trigger: firstPageRef.current,
        scroller: asscroll.containerElement,
        start: 0,
        end: `+=${scrollLength}px`,
        scrub: true,
        pin: true,
        pinType: isTouch ? 'fixed' : 'transform',
      }
    })


    ScrollTrigger.update();
    ScrollTrigger.refresh();

    function once(el, event, fn, opts) {
      var onceFn = function (e) {
        el.removeEventListener(event, onceFn);
        fn.apply(this, arguments);
      };
      el.addEventListener(event, onceFn, opts);
      return onceFn;
    }
    const video = videoRef.current;


    once(video, "loadedmetadata", () => {
      videoTimeline.fromTo(
        video,
        {
          currentTime: 0.01
        },
        {
          currentTime: video.duration || 1
        }
      );

      /* Make sure the video is 'activated' on iOS */
      setiOSPlaying(false)
    });

    ScrollTrigger.update();
    ScrollTrigger.refresh();

    return () => {

      videoTimeline.pause(0).kill(true);
      videoTimeline = null;
      ScrollTrigger.removeEventListener('refresh', resizeHandler);
      ScrollTrigger.getAll().forEach(st => st.kill())
      ScrollTrigger.killAll();
      gsap.ticker.remove(asscroll.update);
      asscroll.disable({ inputOnly: true });

    }
  }, []);


  useLayoutEffect(() => {


    const isTouch = 'ontouchstart' in document.documentElement;


    if (window.matchMedia('(max-width: 800px)') && isTouch) {
      setIsMobile(true)
    }
    else {
      setIsMobile(false)
    }
  }, [width, height])
  return (
    <div asscroll-container={"true"}>
      <Layout>
        <SEO data={seoData} />
        <div ref={firstPageRef} id="THE-PINNED-DIV" className={styles.pinnedDiv}>
          <div className={styles.page} >
            <div className={styles.topPadding}></div>
            <h1 className={styles.title}>{data.markdownRemark.frontmatter.title}</h1>
            <div className={styles.content}>
              <div className={styles.cardContainerWrapper}>
                <div className={styles.cardContainer} ref={cardRefs} >
                  {content.map((el, i) => {
                    return <Card key={el.header} id={i} header={el.header} text={el.text}></Card>
                  })}
                  <Card key={'examples'} id={content.length} header={"Examples"} bulletpoints={exampleBullets} />
                </div>
              </div>

              <div className={styles.animationContainer} >
                <video
                  style={{ width: '100%', height: '100%' }} ref={videoRef} playsInline webkit-playsinline="true" preload="auto" muted autoPlay={iOSPlaying} >
                  <source src={data.markdownRemark.frontmatter.model.publicURL} type="video/mp4"></source>
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className={"spacer"}></div>
        <Page>
          <Flexbox even>
            <div>
              <h2>{contact?.header}</h2>
              <br />
              <br />
              <p>{contact?.text}</p>
            </div>
            <div>
              <div style={{ display: isMobile ? 'block' : 'none' }}></div>
              {isMobile ? <Link to="/contact"><button className={styles.MobileLink}>Request a Quote</button></Link> : <Form />
              }
              <div></div>
            </div>
          </Flexbox>
        </Page>
      </Layout>
    </div >);
}

export default serviceTemplate;

export const query = graphql`
query servicesQuery($id: String) {
  markdownRemark(id: {eq: $id}) {
    id
    frontmatter {
      seo {
        title
        slug
        description
        previewImage {
          publicURL
          extension
          childImageSharp {
            original {
              width
              height
            }
          }
        }
      }
      shortDescription
      title
      contact {
        header
        text
      }
      content {
        header
        text
      }
      examples {
        example
      }
      model {
        publicURL
      }
    }
  }
}

`
