import * as styles from './Card.module.css';

const Card = ({ id, header, text, bulletpoints }) => {
  return (
    <div className={styles.Card} id={id}>
      <h2>{header}</h2>
      {text &&
        <p>{text}</p>
      }
      {bulletpoints &&
        <ul>
          {bulletpoints.map((el, i) => {
            return < li key={i} > {el}</li>
          })}
        </ul>

      }
    </div >
  );
};

export default Card;
